import React, { useState, useRef, useEffect } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './ContactForm.css';
import { db } from './firebase';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    company: '', // New field for company
    time: '', // New field for time
    date: '', // New field for date
    
  });

  const [showForm, setShowForm] = useState(true); // Track form visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleReqCallDataSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const docRef = await addDoc(collection(db, 'contactForms'), {
        name: formData.name,
        phoneNumber: formData.phoneNumber,
        company: formData.company, // Include company in the submission
        time: formData.time, // Include time in the submission
        date: formData.date, // Include date in the submission
        
        timestamp: serverTimestamp(),
      });
      console.log('Document written with ID: ', docRef.id);
      setShowForm(false); 
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };
  
  const handleClosePopup = () => {
    setShowForm(true); 
    setFormData({ name: '', phoneNumber: '', company: '', time: '', date: '',  }); // Clear form data
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\d{10}$/;
    return phoneNumberRegex.test(phoneNumber);
  };


  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };



  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    if (formData.service.includes(value)) {
      setFormData({
        ...formData,
        service: formData.service.filter((item) => item !== value)
      });
    } else {
      setFormData({
        ...formData,
        service: [...formData.service, value]
      });
    }
  };

  

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="contact-form-container">
      {showForm && ( 
        <form onSubmit={handleReqCallDataSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              pattern="[0-9]{10}" // Pattern attribute for HTML5 validation
              title="Please enter a 10-digit phone number"
            />
          </div>

          <div className="form-group">
  <label htmlFor="company">Company:</label>
  <input
    type="text"
    id="company"
    name="company"
    value={formData.company}
    onChange={handleChange}
    required
  />
</div>

<div className='flex gap-x-4'>
<div className="form-group">
  <label htmlFor="time">Preferred Time:</label>
  <input
    type="time"
    id="time"
    name="time"
    value={formData.time}
    onChange={handleChange}
    required
  />
</div>

<div className="form-group">
  <label htmlFor="date">Preferred Date:</label>
  <input
    type="date"
    id="date"
    name="date"
    value={formData.date}
    onChange={handleChange}
    required
  />
</div>
</div>

          {/* <div className="form-group">
        <label htmlFor="service">Choose Service:</label>
        <div className="select-box">
          <div className="selected-options">
          </div>
          <div className="dropdown" ref={dropdownRef}>
            <button className="dropdown-toggle" onClick={toggleDropdown}>
              {selectedCount === 0 ? "Select" : `Selected ${selectedCount}`}
            </button>
            {showDropdown && (
              <div className="dropdown-menu">
                {options.map((category, index) => (
                  <div key={index}>
                    <div className="option-header">{category.category}</div>
                    {category.services.map((option, index) => (
                      <div key={index} className="option">
                        <input
                          type="checkbox"
                          id={option}
                          value={option}
                          checked={formData.service.includes(option)}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor={option}>{option}</label>
                      </div>
                    ))}
                  </div>
                ))}
                <button className='DropdownBtn' onClick={() => setShowDropdown(false)}>Done</button>
              </div>
            )}
          </div>
        </div>
      </div> */}

          <button className='bg-black' type="submit">Request a Call Back</button>
        </form>
      )}

      {!showForm && ( // Conditionally render success message
        <div className="success-message">
          <h4>Hello</h4>
          <h2> {formData.name}</h2>
          <p>We will give you a call back on {formData.phoneNumber}.</p>
          <button className='bg-theme ' onClick={handleClosePopup}>OK</button>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
