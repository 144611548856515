import './App.css';
import BottomNavigation from './components/BottomNavigation';
import Footer from './components/Footer';
import NavBar from './components/Navbar';
import Service from './components/Service';
import HomePage from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Updated import statements
import Sell from './pages/Sell';
import Buy from './pages/Buy';
import Corporate from './pages/Corporate';
import Blog from './pages/Blogs';
import BlogDetail from './components/db/BlogDetail';
import About from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import TermsAndConditions from './pages/Terms&Cond';
import PrivacyPolicy from './pages/Privacy&Policy';
import Timeline from './components/Timeline';
import Product from './components/Products/Product';
import ProductCatelogue from './pages/ProductCatelogue';
import ProductDetails from './components/Products/ProductDetails';

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<HomePage/>} />
          <Route exact path="/sell" element={<Sell/>} />
          <Route exact path="/corporate" element={<Corporate/>} />
          <Route exact path='/timeline' element={<Timeline/>}/>
          <Route exact path="/blogs" element={<Blog/>} />
          <Route path="/blog/:id" element={<BlogDetail/>} />
          <Route path='/about' element={<About/>}/>
          <Route path='/contactus' element={<ContactUs/>}/>
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
          <Route path='/terms-conditions' element={<TermsAndConditions/>}/>

          <Route path='/products' element={<ProductCatelogue/>}/>
          <Route path="/products/:productId" element={<ProductDetails />} />


        </Routes>
        <BottomNavigation />
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
