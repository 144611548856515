// SellCard.js
import React from 'react';
import PropertyImg from '../images/landing.png';
import Collab from '../images/Collab.png';
import Consult from '../images/consult.png'
import Time from "../images/time.png";
import Corp1 from '../images/Corp1.png'
import Corp2 from '../images/Corp2.png'
import Corp3 from '../images/Corp3.png'



const properties = [
  {
    id:'1',
    imageUrl: Corp1,
    heading: "Custom Products",
    description: "You can customize Falhari's products in thousands of your favourite fruit combinations",
    button: 'Collab With Us'
    
  },
  {
    id:'2',
    imageUrl: Corp2,
    heading: "Flexible Ordering",
    description: "Schedule, Takeaways or quick delivery, order the way you wish, we are ready to serve you",
    button: 'Schedule A Meeting'
    
  },
  {
    id:'3',
    imageUrl: Corp3,
    heading: "Dedicated Support Person",
    description: "A dedicated support person to make your experience flawless",
    button:'Get Help'
    
  },
  
];

const SellCard = () => {
  return (
    <div >

    <div className=" container justify-center w-fit m-auto px-4 py-4  sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-4 ">

      {properties.map((property, index) => (
        <div key={index} className="max-w-xs rounded-md overflow-hidden shadow-[0_3px_10px_rgb(0,0,0,0.2)] relative mb-3 hover:border  bg-gradient-to-br from-black  to-orange-400">

        {/* <div className="absolute top-2 left-2 border bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30%  to-90% text-white  px-2 rounded-2xl">{property.tag}</div> */}

          <img className="w-full h-60 object-cover" src={property.imageUrl} alt="Property" />

          {/* Property Details */}
          
          <div className="px-4 py-4">
          <p className="text-4xl leading-none mb-2 text-white">0{property.id}</p>

            <div className="mb-2 flex"><p className='border px-2 rounded-xl bg-blue-100'>{property.flatType}</p></div>
            <div className="font-bold text-xl text-white mb-1 leading-none">{property.heading}</div>
            {/* <div className='flex gap-x-2'>
            <div className="mb-2 flex"><p className='font-bold mr-1'>{property.room}</p> Room</div>
            <div className="mb-2 flex"><p className='font-bold mr-1'>{property.bath}</p> Bath</div>
            <div className="mb-2 flex"><p className='font-bold mr-1'>{property.area}</p> SqFt</div>
            </div> */}
        
            <p className="text-sm leading-none mb-2 text-white">{property.description}</p>
            {/* <button className='border-2 w-full border-gray-800 mb-4 font-medium  px-4 py-2 text-white rounded-full bg-gradient-to-r from-sky-400 to-violet-600'>{property.button}</button> */}

          </div>


        </div>
      ))}
    </div>
    </div>
  );
};

export default SellCard;
