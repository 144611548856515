// src/components/Products/ProductDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ProductData from "../db/ProductsDB/ProductsData";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Bot from '../../images/bot.png';
import { Link, useNavigate } from 'react-router-dom';

const ProductDetails = () => {
  const { productId } = useParams();
  const [whatsappPopup, setWhatsappPopup] = useState(false);
  const [launchShowPopup, setLaunchShowPopup] = useState(false);


  const showWhatsappPopup = () =>{
    setWhatsappPopup(!whatsappPopup);
  };
    

  const LaunchingPopup = () => {
    setLaunchShowPopup(!launchShowPopup);
  }; 


  const handleOrderButtonClick = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 768) {
      LaunchingPopup();
    } else {
      showWhatsappPopup();
    }
  };


  const product = ProductData.find((prod) => prod.id === parseInt(productId));

  if (!product) {
    return <div className="text-center text-red-500 text-xl mt-10">Product not found!</div>;
  }

  return (
    <div className="flex flex-col lg:flex-cols-2 items-center bg-gray-100 min-h-screen p-4">
      <div className="bg-white rounded-lg max-w-md w-full p-6 mt-12">
        <img
          src={product.img}
          alt={product.name}
          className="w-full h-64 object-cover rounded-md"
        />
        <h2 className="text-2xl font-bold text-gray-800 mt-4">{product.name}</h2>
        <p className="text-xl color font-semibold my-2">{product.price}</p>
        <p className="text-gray-600 text-sm leading-relaxed">{product.description}</p>
      </div>

      {/* <div className="mt-4 bg-white p-4 rounded-lg w-full max-w-md">
        <h3 className="text-xl font-semibold mb-2">Recipe</h3>
        
        <h4 className="text-lg font-semibold mt-2">Ingredients</h4>
        <table className="w-full text-left text-gray-700 my-2 border">
          <thead>
            <tr>
              <th className="border p-2">Ingredient</th>
              <th className="border p-2">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {product.recipe?.map((ingredient, index) => (
              <tr key={index}>
                <td className="border p-2">{ingredient.ingredient}</td>
                <td className="border p-2">{ingredient.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>

       
      </div> */}

      {/* Fixed bottom button */}
      <div className="fixed bottom-0 left-0 w-full  p-4 bg-white shadow-lg z-50">
        <button onClick={handleOrderButtonClick} className="flex items-center justify-center w-full lg:w-1/2 lg:m-auto bg-theme text-white font-semibold py-2 rounded-lg hover:bg-orange-600 transition duration-300">
          <AiOutlineShoppingCart className="mr-2 " size={24} />
          Buy Now
        </button>
      </div>

      {launchShowPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded w-2/5">
            <IoIosCloseCircleOutline className="close-icon absolute top-0 right-0 mt-2 mr-2 cursor-pointer text-black font-bold text-2xl" onClick={LaunchingPopup} />
            <img className='w-4/6  m-auto object-cover' src={Bot} alt="Launching soon bot" />
            <p className='text-xl font-bold'>Ordering Made easy</p>
            <p className='text-3xl font-bold'>Order Directly Via <span className='text-green-500'>Whatsapp</span></p>

            <p className='text-xl font-medium text-red-500'>*Note:Please Use Mobile for Better experience</p>
            <button className='bg-theme px-4 py-2 text-white text-semibold rounded' onClick={LaunchingPopup}>OK</button>
          </div>
        </div>
      )}


      
      {whatsappPopup && (
        <div className="popup-wrapper ">
          <div className="popup-content">
            <IoIosCloseCircleOutline className="close-icon" onClick={showWhatsappPopup} />

            <img className='w-4/6  m-auto object-cover' src={Bot} alt="Launching soon bot" />
            <p className='text-xl font-medium leading-none'>Ordering Made easy</p>
            <p className='text-3xl font-bold'>Order Directly Via <span className='text-green-500'>Whatsapp</span></p>
            <Link to='https://wa.me/message/5TJSEZZBZM5LM1'>
            <button  className='bg-theme px-4 py-2 text-white text-semibold rounded w-full'>Order Now</button>
            </Link>
          </div>
        </div>
      )}
    </div>

    
  );
};

export default ProductDetails;
