// Sell.js
import React from 'react';
import ServiceImage from '../images/service.png'; // Import your service image
import SellCard from '../components/SellCard';
import Market from '../images/market.png'


const Sell = () => {
  return (
    <div className=''>
    <div className="h-96">
      <img src={ServiceImage} alt="Service" className="absolute inset-0 w-full h-80 object-cover mt-16" />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-60 h-80 mt-16"></div>
      <div className="absolute top-48 left-4 text-white">
        <h1 className="text-xl md:text-2xl font-bold">Sell Your</h1>
        <p className="text-4xl md:text-6xl text-white font-bold w-fit  lg:text-8xl from-orange-600 via-pink-600 to-orange-600 bg-gradient-to-r bg-clip-text text-transparent">Property</p>
        <h1 className="text-md mb-2 md:text-2xl font-light">At the best deal just at the right time</h1>
        <button className="bg-gradient-to-r from-indigo-500 from-10% via-pink-500 via-30%  to-90% border-2 hover:bg-none-700 text-white font-bold py-2 px-4 md:px-12 rounded-full">Contact Us</button>
      </div>
      
    </div>
    <div>
    <SellCard/>
    </div>

    <div className='border w-11/12 md:w-3/5 md:w-5/6  mb-4 p-4  md:pl-12 rounded-lg h-60 m-auto bg-cover bg-center pt-24 lg:pt-20' style={{backgroundImage: `url(${Market})`}}>
    <h1 className='text-white font-bold lg:text-4xl  md:w-3/5 md:text-3xl text-xl mb-2 '>Dont Miss the Jump in the real estate market</h1>
    <button className="bg-gradient-to-r from-indigo-500 from-10% via-blue-500 via-30%  to-90% border-2 hover:bg-none-700 text-white font-bold py-2 px-8 md:px-12 rounded-full">Get Offers</button>

    </div>



    </div>
  );
};

export default Sell;
