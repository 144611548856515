// BottomNavigation.js
import React, { useState, useEffect } from 'react';
import { RiHome2Line, RiUserLine, RiSettings2Line } from 'react-icons/ri';
import { RiKeyLine } from "react-icons/ri";
import Blogs from '../images/Blogs.png';

import { LiaSellsy } from "react-icons/lia";
import { Link, useNavigate } from 'react-router-dom';

import { FaWhatsapp } from "react-icons/fa";
import { CiGrid41 } from "react-icons/ci";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ContactForm from '../components/ContactForm';
import Bot from '../images/bot.png';
import Plans from '../images/Plans.png';





const BottomNavigation = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeItem, setActiveItem] = useState('home'); // State to track active item

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

 
  
  const [showPopup, setShowPopup] = useState(false);
  const [launchShowPopup, setLaunchShowPopup] = useState(false);
  const [whatsappPopup, setWhatsappPopup] = useState(false);
  const [subscriptionPopup, setSubscriptionPopup] = useState(false);


  const handlePopupButtonClick = () => {
    setShowPopup(false); // Close the popup when a button inside it is clicked
    setWhatsappPopup(false)
    setSubscriptionPopup(false)

  
  }; 

  const ShowSubcriptionPopup = () =>{
    setSubscriptionPopup(!subscriptionPopup);
  };

  const LaunchingPopup = () =>{
    setLaunchShowPopup(!launchShowPopup);
  };

  const showWhatsappPopup = () =>{
    setWhatsappPopup(!whatsappPopup);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleItemClick = (item) => {
    setActiveItem(item); // Set the active item
  };

  if (!isMobile) {
    return null; 
  }

  return (
    <div>
    <nav className="z-40 fixed bottom-0 left-0 right-0 bg-white px-4 py-2 flex justify-around items-center text-black border-t rounded-t-xl shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
      <Link to='/'  className={`flex flex-col items-center ${activeItem === 'home' ? 'text-orange-600' : ''}`} onClick={() => handleItemClick('home')}>
        <RiHome2Line className="text-2xl" />
        <span className="text-xs mt-1">Home</span>
      </Link>
      <Link to='/corporate' className={`flex flex-col items-center ${activeItem === 'corporate' ? 'text-orange-500' : ''}`} onClick={() => handleItemClick('corporate')}>
        <HiOutlineBuildingOffice2 className="text-2xl" />
        <span className="text-xs mt-1">Corporate</span>
      </Link>
      <Link to='#' className={`flex flex-col items-center ${activeItem === 'order' ? 'text-orange-500' : ''}`} onClick={showWhatsappPopup}>
        <IoBagHandleOutline className="text-2xl" />
        <span className="text-xs mt-1">Order</span>
      </Link>
      <Link to='#' className={`flex flex-col items-center ${activeItem === 'settings' ? 'text-orange-500' : ''}`}  onClick={togglePopup}>
        <CiGrid41 className="text-2xl" />
        <span className="text-xs mt-1 ">More</span>
      </Link>
    </nav>

    {showPopup && (
        <div className="popup-wrapper ">
          <div className="popup-content">
            <IoIosCloseCircleOutline className="close-icon" onClick={togglePopup} />

            <div className=' w-full py-8 justify-center m-auto'>
            
            <button onClick={() => { handlePopupButtonClick(); ShowSubcriptionPopup(); }} className='border-2 mb-2 rounded-md px-2 py-1 font-medium text-black text-lg lg:text-2xl lg:px-4 w-full'>Falhari Plus Plan</button>
            {/* <button onClick={() => { handlePopupButtonClick(); LaunchingPopup(); }} className='border-2 mb-2 rounded-md  px-2 py-1 font-medium text-black text-lg w-full lg:text-2xl lg:px-4'>Blogs</button> */}

            <Link to='/blogs'>
            <button className='border-2 mb-2 px-2 py-1 font-medium text-black text-lg lg:text-2xl lg:px-4 w-full rounded-md'>Blogs</button>
            </Link>
            
            <a href="https://app.falhari.com/store/falhari-falhari-sg-palya-takeaway/acidity-meter"  >
            <button className='border-2 mb-2 px-2 py-1 font-medium text-black text-lg lg:text-2xl lg:px-4 w-full rounded-md'>Acidity Checker</button>
            </a>
            <a href="mailto:hello@falhari.com"  >
            <button className='border-2  px-2 py-1 font-medium text-black text-lg lg:text-2xl lg:px-4 w-full rounded-md'>Mail Us</button>
            </a>
          
        </div>
          </div>
        </div>
      )}

      {subscriptionPopup && (
        <div className="popup-wrapper ">
          <div className="popup-content">
            <IoIosCloseCircleOutline className="close-icon" onClick={ShowSubcriptionPopup} />

            <img className='w-4/6 h-60  m-auto object-cover' src={Plans} alt="Launching soon bot" />
            <p className='text-2xl font-bold leading-none color'>Subscription Plan</p>
            <p className='text-md font-medium'>Become a Falhari Plus member and get extra rewards, priority support, and member-only deals</p>
            <Link to='https://wa.me/message/5TJSEZZBZM5LM1'>
            <button  className='bg-theme px-4 py-2 text-white text-semibold rounded w-full'>Check Plans</button>
            </Link>
          </div>
        </div>
      )}

      {whatsappPopup && (
        <div className="popup-wrapper ">
          <div className="popup-content">
            <IoIosCloseCircleOutline className="close-icon" onClick={showWhatsappPopup} />

            <img className='w-4/6  m-auto object-cover' src={Bot} alt="Launching soon bot" />
            <p className='text-xl font-medium leading-none'>Ordering Made easy</p>
            <p className='text-3xl font-bold'>Order Directly Via <span className='text-green-500'>Whatsapp</span></p>
            <Link to='https://wa.me/message/5TJSEZZBZM5LM1'>
            <button  className='bg-theme px-4 py-2 text-white text-semibold rounded w-full'>Order Now</button>
            </Link>
          </div>
        </div>
      )}

      {launchShowPopup && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded lg:w-2/5 w-5/6 ">
              <IoIosCloseCircleOutline className="close-icon absolute top-0 right-0 mt-2 mr-2 cursor-pointer text-white font-bold text-2xl" onClick={LaunchingPopup} />
              <img className='lg:w-5/6 w-full lg:m-auto ' src={Blogs}/>
              <p className='lg:text-3xl font-bold text-xl leading-none '>Launching Soon</p>
              <p className='lg:text-xl text-md  font-medium'>Stay tuned</p>
              <button className='bg-theme px-4 py-2 text-white text-semibold rounded'  onClick={LaunchingPopup}>OK</button>
            </div>
          </div>
        )}
    </div>
  );
};

export default BottomNavigation;
