import React from 'react';
import FalhariST from '../images/FalhariST.png'
import Insta1 from '../images/Insta1.png';
import Insta02 from '../images/Insta02.png';
import Insta03 from '../images/Insta03.png';
import InstaNew from '../images/InstaNew.png';


import INF1 from '../images/INF1.png';


const InstagramData =[
  {
    id:'1',
    imageUrl: Insta1,
    urlLink:'https://www.instagram.com/reel/C6jOQvORzA6/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
  },
  {
    id:'2',
    imageUrl: InstaNew,
    urlLink:'https://www.instagram.com/reel/C63sKpeSJo1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
  },
  {
    id:'3',
    imageUrl: Insta02,
    urlLink:'https://www.instagram.com/reel/C4Cre1JPDZR/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
  },
  {
    id:'4',
    imageUrl: Insta03,
    urlLink:'https://www.instagram.com/reel/C4A8AOnt1-f/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
  },
  {
    id:'5',
    imageUrl: INF1,
    urlLink:"https://www.instagram.com/reel/CqXKItpgt-T/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
  }
]


const InstagramPost = () => {

  return (
    <div className='flex gap-x-2 overflow-y-auto no-scrollbar' >
      {InstagramData.map((data, index) => (
        <div key={data.id} className='w-3/5'>
          <blockquote className="w-full h-76 " data-instgrm-permalink={data.urlLink} data-instgrm-version="14" style={{ background: '#FFF', border: '0', borderRadius: '3px', boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', }}>
            <div style={{ padding: '0px' }} className='w-full'>
              <a href={data.urlLink} style={{ background: '#FFFFFF', lineHeight: '0', padding: '0 0', textAlign: 'center', textDecoration: 'none', width: '100%' }} target="_blank">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className='px-2 py-1'>
                  <div style={{ backgroundColor: '#F4F4F4', borderRadius: '50%', flexGrow: '0', height: '40px', marginRight: '14px', width: '40px' }}></div>
                  <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: '#F4F4F4', borderRadius: '4px', flexGrow: '0', height: '14px', marginBottom: '6px', width: '100px' }}></div>
                    <div style={{ backgroundColor: '#F4F4F4', borderRadius: '4px', flexGrow: '0', height: '14px', width: '60px' }}></div>
                  </div>
                </div>
                <div  style={{ paddingTop: '8px' }} className='w-full'>
                  <div className='w-full'><img className='h-60 w-full object-cover' src={data.imageUrl} alt="Instagram Post"/></div>
                </div>
                <div style={{ padding: ' ' }}></div>
                <div className='px-2' style={{ display: 'flex', flexDirection: 'row', marginBottom: '0px', alignItems: 'center' }}>
              <div>
                <div style={{ backgroundColor: '#F4F4F4', borderRadius: '50%', height: '12.5px', width: '12.5px', transform: 'translateX(0px) translateY(7px)' }}></div>
                <div style={{ backgroundColor: '#F4F4F4', height: '12.5px', transform: 'rotate(-45deg) translateX(3px) translateY(1px)', width: '12.5px', flexGrow: '0', marginRight: '14px', marginLeft: '2px' }}></div>
                <div style={{ backgroundColor: '#F4F4F4', borderRadius: '50%', height: '12.5px', width: '12.5px', transform: 'translateX(9px) translateY(-18px)' }}></div>
              </div>
              <div style={{ marginLeft: '8px' }}>
                <div style={{ backgroundColor: '#F4F4F4', borderRadius: '50%', flexGrow: '0', height: '20px', width: '20px' }}></div>
                <div style={{ width: '0', height: '0', borderTop: '2px solid transparent', borderLeft: '6px solid #f4f4f4', borderBottom: '2px solid transparent', transform: 'translateX(16px) translateY(-4px) rotate(30deg)' }}></div>
              </div>
              <div className='' style={{ marginLeft: 'auto' }}>
                <div style={{ width: '0px', borderTop: '8px solid #F4F4F4', borderRight: '8px solid transparent', transform: 'translateY(16px)' }}></div>
                <div style={{ backgroundColor: '#F4F4F4', flexGrow: '0', height: '12px', width: '16px', transform: 'translateY(-4px)' }}></div>
                <div style={{ width: '0', height: '0', borderTop: '8px solid #F4F4F4', borderLeft: '8px solid transparent', transform: 'translateY(-4px) translateX(8px)' }}></div>
              </div>
            </div>
              </a>
            </div>
          </blockquote>
        </div>
      ))}
      <script async src="//www.instagram.com/embed.js"></script>
    </div>
  );
};

export default InstagramPost;
