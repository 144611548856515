import React from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';
import './Product.css'
const Product = ({ products }) => {
  const navigate = useNavigate();

  const handleCardClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  return (
    <div className="Product lg:p-4">
      {products.length > 0 ? (
        <div className="Recommended-Cards">
          <div className="Recommended grid grid-cols-2 lg:grid-cols-4 gap-6">
            {products.map((product) => (
              <div
                className="Rec-Card cursor-pointer shadow-lg hover:shadow-xl transition-all duration-300 rounded-lg overflow-hidden"
                key={product.id}
                onClick={() => handleCardClick(product.id)}
              >
                <div className="ProdI">
                  <img
                    className="Prod-Img rounded-t-md w-full h-60 object-cover"
                    src={product.img}
                    alt={product.name}
                  />
                </div>
                <div className="Prod-Heading p-2">
                  <div className="Prod-Name ">
                    <h3 className="text-lg font-semibold">{product.name}</h3>
                  </div>
                  <div className="Prod-Price">
                    <h3 className="text-gray-600">₹{product.price}</h3>
                  </div>
                </div>
                <div className="Prod-Description px-2  text-sm text-gray-500">
                  <p>{product.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className=" message-box rounded-lg flex justify-center items-center h-96  lg:w-4/5 m-auto border border-gray-200 shadow-md ">
      <div className=" p-6 text-center rounded-lg">
        {/* Icon */}
        <div className="flex justify-center items-center mb-4">
          <FaSearch className="color text-5xl mb-2" />
        </div>
        {/* Message */}
        <p className="text-gray-700 text-lg font-semibold">
          No health goals selected, please search your health goals to view products.
        </p>
        {/* Optional Button */}
        <button className="mt-4 px-6 py-2 bg-theme text-white rounded-full hover:bg-blue-600 transition-all duration-300">
          Search Now
        </button>
      </div>
    </div>
      )}
    </div>
  );
};

export default Product;
