import React, { useState, useEffect } from 'react';
import LandingPage from '../components/LandingPage';
import PropertyCard from '../components/PropertyCard';
import Service from '../components/Service';
import LandingImg from '../images/landing.png'
import Landing from '../components/Landing';
import CategoryCard from '../components/Category';
import Clinic from '../images/Clinic.png'
import City1 from '../images/City1.png';
import City2 from '../images/City2.png';
import City3 from '../images/City3.png';
import YtEmbed from '../components/YtEmbed';
import InstagramPost from '../components/InstagramPost';
import { Link } from 'react-router-dom';
import { IoIosCloseCircleOutline } from "react-icons/io";
import Bot from '../images/bot.png';



const HomePage = () => {

  const [launchShowPopup, setLaunchShowPopup] = useState(false);
  const [whatsappPopup, setWhatsappPopup] = useState(false);

  

  const LaunchingPopup = () => {
    setLaunchShowPopup(!launchShowPopup);
  };

  const showWhatsappPopup = () =>{
    setWhatsappPopup(!whatsappPopup);
  };

  const handleOrderButtonClick = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 768) {
      LaunchingPopup();
    } else {
      showWhatsappPopup();
    }
  };

  return (
    <div className='md:w-11/12 m-auto lg:w-full'>
    <div className='pb-6 mb-28'>      
    <Landing/>  
    </div>

      <div className='flex gap-x-2 mt-8 lg:h-fit h-44 lg:mb-12  w-11/12 m-auto'>
      <div className='text-md  font-bold rotate-[270deg] leading-tight h-fit w-1/5 lg:w-1/5 py-20  lg:py-0 lg:pt-48'   >
      <h1 className='leading-none text-3xl lg:text-6xl text-right' style={{ 
    color: '#fff', // Text color
    textAlign:'center',
    textShadow: '-1px -1px 0 orange, 1px -1px 0 orange, -1px 1px 0 orange, 1px 1px 0 orange' // Stroke effect
  }}>Fresh <br></br> <span className='color leading-none'>Everyday</span></h1>      
  
      </div>
      <div className='w-4/5 mr-2  lg:pt-16 lg:pl-16'>
      <h1 className='font-medium text-xl pt-8 pb-1 leading-none md:text-2xl lg:text-5xl'>Discover The Best Fruit Products</h1>
      <h1 className='font-medium text-gray-500 text-sm leading-none md:text-md lg:text-2xl'>Freshly Procured, Hygienically Prepared, & Quickly Delivered</h1>
      <div className='h-1 w-full rounded-full bg-black mt-2 md:w-3/5'></div>
      {/* <p className='font-medium text-sm '>Falhari’s amazing fruit products are a must to try. Huge collection of fruit products, catering all your needs from craving to snacking</p> */}
      </div>


      </div>

      <div className=' md:w-11/12 m-auto mb-6 '>
      {/* <h1 className='w-fit mb-2 pl-2 text-xl font-bold w-fit justify-start'>Popular Categories</h1> */}
        <CategoryCard/>
      </div>

      <div className=' md:flex items-center border m-2 pb-2 rounded-lg lg:w-4/6 lg:pl-4 lg:border-t lg:m-auto '>
        <img className='w-full md:w-2/5  h-fit object-cover lg:p-2' src={Clinic}/>
        <div className='mx-2 md:w-3/5 md:py-28'>
        <h1 className='font-bold text-xl lg:text-4xl color'>Falhari’s</h1>
        <h1 className='text-3xl font-semibold lg:text-6xl lg:bg-'>Innovative Fruit Product</h1>
        <h1 className='text-md font-medium text-gray-600 lg:text-3xl'>For bones, skin, hair & more</h1>
        
        <button onClick={handleOrderButtonClick}  className='px-4 w lg:px-6 lg:text-2xl py-2 my-2 border-2 border-black bg-black text-white font-bold'>Explore Now →</button>
       
        </div>
      </div>

      {/* Cities */}
      <div className=' bg-indigo-200 py-4 px-2 mt-4 '>
        <h1 className=' font-bold text-3xl  lg:text-4xl  leading-none lg:text-center'>Cities</h1>
        <h1 className='text-xl lg:text-2xl color font-medium lg:text-center'>We currently serve in</h1>
        
        <div className='flex gap-x-1 lg:gap-x-6 pt-3 justify-center'>
          <img className='w-28 lg:w-52 lg:h-48 h-24 border-2 border-red-500 lg:shadow-md object-cover rounded-2xl' src={City1}/>
          <img className='w-28 h-24 lg:shadow-md lg:w-52 lg:h-48 border-2 border-red-500  object-cover rounded-2xl' src={City2}/>
          <img className='w-28 h-24 lg:w-52 lg:h-48 lg:shadow-md border-2 border-red-500 object-cover rounded-2xl' src={City3}/>

        </div>
      </div>

      {/* YT Embed */}
      <div className='px-2 py-16  pb-12 md:flex md:items-center md:gap-x-32 md:w-5/6 md:m-auto'>
      <div>
        <h1 className='font-bold leading-none pl-2'> <span className='text-black text-5xl lg:text-6xl'>Falhari</span></h1>
        <h1 className='text-md lg:text-xl color leading-none font-medium pb-2 pl-3'>Shark Tank India S01</h1>
        <Link to='https://youtu.be/8_AiIThX93c?feature=shared'>
      <button className='lg:text-lg border-2 border-black font-bold px-2 py-2  text-sm  mx-3 mb-4'>View On Youtube →</button>
       </Link> 

      </div>
      <div className=''>
        <YtEmbed/>
        </div>
      </div>

      <div className='w-11/12 m-auto h-auto'>
      <div>
        <h1 className='text-xl font-bold leading-none '> <span className='text-black text-4xl lg:text-6xl '>Influencers</span></h1>
        <h1 className='text-lg leading-none font-medium pb-2 color'>Reviewing Our Products</h1>
      </div>
        <InstagramPost/>
      </div>

      {launchShowPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded w-2/5">
            <IoIosCloseCircleOutline className="close-icon absolute top-0 right-0 mt-2 mr-2 cursor-pointer text-black font-bold text-2xl" onClick={LaunchingPopup} />
            <img className='w-4/6  m-auto object-cover' src={Bot} alt="Launching soon bot" />
            <p className='text-xl font-bold'>Ordering Made easy</p>
            <p className='text-3xl font-bold'>Order Directly Via <span className='text-green-500'>Whatsapp</span></p>

            <p className='text-xl font-medium text-red-500'>*Note:Please Use Mobile for Better experience</p>
            <button className='bg-theme px-4 py-2 text-white text-semibold rounded' onClick={LaunchingPopup}>OK</button>
          </div>
        </div>
      )}

      {whatsappPopup && (
        <div className="popup-wrapper ">
          <div className="popup-content">
            <IoIosCloseCircleOutline className="close-icon" onClick={showWhatsappPopup} />

            <img className='w-4/6  m-auto object-cover' src={Bot} alt="Launching soon bot" />
            <p className='text-xl font-medium leading-none'>Ordering Made easy</p>
            <p className='text-3xl font-bold'>Order Directly Via <span className='text-green-500'>Whatsapp</span></p>
            <Link to='https://wa.me/message/5TJSEZZBZM5LM1'>
            <button  className='bg-theme px-4 py-2 text-white text-semibold rounded w-full'>Order Now</button>
            </Link>
          </div>
        </div>
      )}
      
    </div>
  );
};

export default HomePage;
