import React, { useState } from 'react';
import ServiceImage from '../images/service.png';
import { IoIosCloseCircleOutline } from "react-icons/io";
import ContactForm from '../components/ContactForm';
// import corporateCard from '../components/corporateCard';
import Market from '../images/market.png';
import Office from '../images/CorpLanding.png';
import ClientsSection from '../components/ClientCrousel';
import SellCard from '../components/SellCard';
import CHO from "../images/CHO1.png";
import './Corporate.css';


const Corporate = () => {

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className=''>
    <div className="h-96 pt-16 mb-8 lg:flex lg:h-500px lg:w-10/12 lg:m-auto">
    <div className='lg:w-1/2 '>
    <img className='w-full h-60 lg:h-500px lg:w-full m-auto object-cover' src={Office}/>
    </div>
    <div className='px-4 lg:w-1/2 lg:px-2 lg:pl-24 lg:py-20'>
      <h1 className='text-2xl font-semibold lg:text-6xl'>Give Your Employees a</h1>
      <h1 className='text-4xl font-bold color pb-2 lg:text-6xl'>#FalhariTreat</h1>

      <div className='flex gap-x-2  m-auto pl-1 lg:pl-1 lg:w-full '>
        <a href="mailto:hello@falhari.com" className='border-2 border-black px-2 py-1 font-medium text-white text-sm bg-black lg:text-2xl lg:px-4' >
        Mail Us
        </a>
        <button onClick={togglePopup} className='border-2 border-black px-2 py- font-medium text-black bg-white text-sm lg:text-2xl' >
        Schedule a Meet
        </button>
    </div>
    
    </div>
    
    </div>
    <div className=''>
    {/* <div className=''>
    <h1 className='leading-none'>Our</h1>
    <h1 className='text-xl font-bold leading-none'>Clients</h1>
    </div> */}
    <div className='mt-20 lg:mt-0'>
    <hr></hr>
    <ClientsSection/>
    <hr></hr>
    </div>
    {/* <corporateCard/> */}
    </div>
    
    <div className='flex gap-x-2 mt-8 lg:h-fit h-44  lg:mb-12  w-11/12 m-auto'>
      <div className='text-md  font-bold rotate-[270deg] leading-tight h-fit w-1/5 lg:w-1/5 py-20  lg:py-0 lg:pt-48'   >
      <h1 className='leading-none text-3xl lg:text-6xl text-right' style={{ 
    color: '#fff', // Text color
    textAlign:'center',
    textShadow: '-1px -1px 0 orange, 1px -1px 0 orange, -1px 1px 0 orange, 1px 1px 0 orange' // Stroke effect
  }}>Healthy <br></br> <span className='color leading-none'>Lifestyle</span></h1>      
  
      </div>
      <div className='w-4/5 mr-2 lg:pt-16 pl-4 lg:pl-16'>
      <h1 className='font-medium text-xl pt-6 lg:pt-8  pb-1 leading-none md:text-2xl lg:text-5xl'>Making Corporate Culture FruitFul</h1>
      <h1 className='font-medium text-gray-500 text-sm leading-none md:text-md lg:text-2xl'>Falhari's Innovative Fruit Products Are Crafted for Modern Lifestyles</h1>
      <div className='h-1 w-full rounded-full bg-black mt-2 md:w-3/5'></div>
      {/* <p className='font-medium text-sm '>Falhari’s amazing fruit products are a must to try. Huge collection of fruit products, catering all your needs from craving to snacking</p> */}
      </div>


      </div>

<div>
  <SellCard/>
</div>

    {/* <div className='border w-11/12 md:w-3/5 md:w-5/6  mb-4 p-4  md:pl-12 rounded-lg h-60 m-auto bg-cover bg-center pt-24 lg:pt-20' style={{backgroundImage: `url(${Market})`}}>
    <h1 className='text-white font-bold lg:text-4xl  md:w-3/5 md:text-3xl text-xl mb-2 '>Dont Miss the Jump in the real estate market</h1>
    <button className="bg-gradient-to-r from-indigo-500 from-10% via-blue-500 via-30%  to-90% border-2 hover:bg-none-700 text-white font-bold py-2 px-8 md:px-12 rounded-full">Get Offers</button>

    </div> */}
  
    <div className='lg:flex lg:w-10/12 lg:m-auto'>
    <div className='lg:w-1/2'>
    <img className='lg:w-4/5 lg:h-fit object-cover ml-auto' src={CHO}/>
    </div>
   
      <div className='lg:w-1/2 lg:py-32 pl-6'>
      <h1 className='text-xl pl-1 font-medium lg:text-3xl ' >Appoint Us As</h1>
      <h1 className='text-4xl font-bold color lg:text-6xl w-5/6'>Chief Health Officer</h1>
  
    <div className='flex gap-x-2  m-auto pl-1 mt-2'>
        <a href="mailto:hello@falhari.com" className='border-2 border-black px-2 lg:px-4 py-1 lg:text-2xl font-medium text-white text-sm bg-black' >
        Mail Us
        </a>
        <button onClick={togglePopup} className='border-2 border-black px-2 py- font-medium text-black bg-white text-sm lg:text-2xl' >
        Schedule a Meet
        </button>
    </div>
    </div>
    </div>

    {showPopup && (
        <div className="popup-wrapper">
          <div className="popup-content">
            <IoIosCloseCircleOutline className="close-icon" onClick={togglePopup} />
            <ContactForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default Corporate;
