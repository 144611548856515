import Product1 from "../../../assets/productCategory/VratAmrit Fruit Salad.jpg";
import Product2 from "../../../assets/productCategory/Trail Mix.jpg";
import Product3 from "../../../assets/productCategory/Exotico Salad.jpg";
import Product4 from "../../../assets/productCategory/Hangover Bliss Salad.jpg";
import Product5 from "../../../assets/productCategory/ProteinBooster.jpg";
import Product6 from "../../../assets/productCategory/Fruit Sakran.jpg";
import Product7 from "../../../assets/productCategory/PCOS.png";
import Product8 from "../../../assets/productCategory/Body Toner Salad.jpg";
import Product9 from "../../../assets/productCategory/Fat Cutter.jpg";

import Product10 from "../../../assets/productCategory/Orange Single.png";


const ProductData = [
  {
    id: 1,
    img: Product1,
    name: "VratAmrit Fruit Salad",
    price: "Rs 200",
    tags: ["Immunity", "Heart health"],
    description:"If you are on a fast, this salad is just the force that the Universe wants to be with you. Pomegranate, Kiwi, Banana, Pineapple, Watermelon || Makhana, Walnuts, Almonds and Brown Raisins.",
    recipe: [
      { ingredient: "Pomegranate", quantity: "80g" },
      { ingredient: "Banana", quantity: "80g" },
      { ingredient: "Kiwi", quantity: "80g" },
      { ingredient: "Watermelon", quantity: "80g" },
      { ingredient: "Pineapple", quantity: "80g" },
      { ingredient: "Almond", quantity: "5g" },
      { ingredient: "Walnut", quantity: "5g" },
      { ingredient: "Brown Kishmish", quantity: "5g" },
      { ingredient: "Makhana", quantity: "10g" }
    ]
  },

  {
    id: 2,
    img: Product2,
    name: "Dry Fruit Mix",
    price: "Rs 80",
    tags: ["High cholesterol", "Heart health", "Memory"],
    description:"A combination of almonds, walnuts, raisins, pumpkin seeds, flax seeds, and dried cranberries to fill your snacktime with taste and health.",
    recipe: [
      { ingredient: "Almond", quantity: "5g" },
      { ingredient: "Walnut", quantity: "5g" },
      { ingredient: "Brown Raisin", quantity: "5g" },
      { ingredient: "Flax Seeds", quantity: "5g" },
      { ingredient: "Pumpkin Seed", quantity: "5g" },
      { ingredient: "Cranberry", quantity: "5g" },      
    ]
  },
  {
    id: 3,
    img: Product3,
    name: "Exotico Salad",
    tags: ["Cold & flu", "Immunity", "Vision", "Fatty Liver", 'IBS'],
    price: "Rs 300",
    description:"This is the real exotic thing to start your rewarding journey with fruits. Pineapple, Honeymelon, Apple, Kiwi, Malta, Red Grapes, Pomegranate, Strawberries || Blueberries, Cranberries, Mint Leaves",
    recipe: [
      { ingredient: "Pineapple", quantity: "60g" },
      { ingredient: "Honeymelon", quantity: "60g" },
      { ingredient: "Apple", quantity: "60g" },
      { ingredient: "Pomegranate", quantity: "50" },
      { ingredient: "Egyptian Orange", quantity: "50g" },
      { ingredient: "Kiwi", quantity: "50g" },
      { ingredient: "Cranberry", quantity: "5g" },
      { ingredient: "Blueberry", quantity: "5g" },
      { ingredient: "Mint", quantity: "5g" },
      { ingredient: "Strawberry", quantity: "5g" },


    ]
  },
  {
    id: 4,
    img: Product4,
    name: "Hangover Bliss",
    tags: ["Constipation", "Hydration", "Digestion", "acidity"],
    price: "Rs 300",
    description:"Say goodbye to hungover mornings with this refreshing combination of fruits, dry fruits and coconut water. Salad (Banana, Kiwi, Dates, Watermelon, Pear || Almonds, Walnut, Blueberry, Mint Leaves, Lemon) + Coconut Water",
    recipe: [
      { ingredient: "Frozen Banana", quantity: "1 whole" },
      { ingredient: "Mixed Berries", quantity: "100g" },
      { ingredient: "Almond Milk", quantity: "50ml" },
      { ingredient: "Granola", quantity: "30g" },
      { ingredient: "Chia Seeds", quantity: "1 tsp" },
      { ingredient: "Sliced Fruits (kiwi, banana)", quantity: "50g" }
    ]
  },
  {
    id: 5,
    img: Product5,
    name: "Protein Booster",
    tags: ["Muscle health", "Heart health", "Blood pressure"],
    price: "Rs 250",
    description:"A super-blend of fruits, dry fruits and chocolate that provides instant energy and enhances health. Banana, Honeymelon, Red Grapes, Pomegranate || Chocolate, Dates, Cashewnut, Pistachios",
  },

  {
    id: 6,
    img: Product6,
    name: "Fruit Sakran",
    tags: ["Probiotic", "Anaemia", "Digestion"],
    price: "Rs 250",
    description:"gut-healthy combo with 1 Fruit Salad of your choice.",
  },
  
  {
    id: 7,
    img: Product7,
    name: "PCOS Box",
    tags: ["PCOS", "IBS", "Improve mood", "Improve energy levels"],
    price: "Rs 300",
    description:"Recommended recipe from our nutritionist for PCOS. Contains Banana, Pomegranate, Red Grapes, Tomato, Almonds, Walnut, Orange, Plum, Dried Cranberry, Flax Seeds and Dried Blueberry. These ingredients are highly effective in controlling and curing PCOS naturally.",
  },
  
  {
    id: 8,
    img: Product8,
    name: "Body Toner",
    tags: ["Digestion", "Anti-inflammatory",],
    price: "Rs 160",
    description:"A blend of fruits and seeds for strengthen the body and keep it in shape. Papaya, Pear, Watermelon, Apple, Pineapple, Orange || Flax Seed",
  },
  
  {
    id: 9,
    img: Product9,
    name: "Fat Cutter",
    tags: ["Metabolism", "Glowing Skin", "Muscle health", "Eye health"],
    price: "Rs 250",
    description:"While cruising your way through the layers of existence, you need something that fills you up and yet ends up creating more room for growth on this fitness journey. Cut through yourself with Apple, Pineapple, Watermelon, Orange, Tomato topped up with Mint Leaves!",
  },
  {
    id: 10,
    img: Product10,
    name: "Egyptian Orange",
    tags: ["Immunity", "diabetes", "IBS"],
    price: "Rs 95",
    description:"Malta is high in nutrients including Potassium, Vitamin C, Folate, Manganese, Dietary Fibre, and Magnesium!",
  },
];

export default ProductData;
