import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBasgyNT0Z_z5kLJy0S4H2AEQS0mvug6ls",
  authDomain: "falhari-fruitfulhabit.firebaseapp.com",
  projectId: "falhari-fruitfulhabit",
  storageBucket: "falhari-fruitfulhabit.appspot.com",
  messagingSenderId: "806872696515",
  appId: "1:806872696515:web:2b11c5d23b72b7029c0476",
  measurementId: "G-7BZQD7FCQ9"
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export {
  app,
  auth,
  db,
  storage,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  collection,
  getDocs
};
