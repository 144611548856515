// Footer.js
import React from 'react';
import { MdEmail, MdPhone } from 'react-icons/md';
import LogoFooter from '../images/LogoFotter.png';
import { FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaMapLocationDot } from 'react-icons/fa6';

const Footer = () => {
  return (
    <div className='mb-16 lg:mb-0'>
      <footer className="bg-white text-black py-8 mb-2 bg-gradient-to-b from-white via-white to-orange-600">
        <hr />
        <div className="container mx-auto px-4">
          <div className='lg:w-5/6 lg:m-auto' style={{ width: '170px', height: '70px' }}>
            <img style={{ width: '170px', height: '80px', objectFit: 'cover' }} src={LogoFooter} alt="Logo" />
          </div>
          <div className="flex gap-x-12 lg:h-20 mb-2 mt-2 items-center lg:w-fit lg:m-auto">
            <Link to='https://www.instagram.com/gofalhari/?hl=en'>
              <FaInstagram className="text-white font-bold text-4xl bg-black rounded-md p-2 mr-2" />
            </Link>

            <Link to='https://www.facebook.com/falhari'>
              <FaFacebookF className="text-white font-bold text-4xl bg-black rounded-md p-2 mr-2" />
            </Link>

            <Link to='https://in.linkedin.com/company/falhari'>
              <FaLinkedinIn className="text-white font-bold text-4xl bg-black rounded-md p-2 mr-2" />
            </Link>
          </div>
          <h1 className='text-lg font-medium pb-2 lg:text-center lg:text-xl'>Contact Us</h1>

          <div className="flex flex-col md:flex-row md:items-center lg:grid lg:grid-cols-3 lg:place-items-center lg:w-4/5 lg:m-auto">
            <div className="mb-2 md:mb-0">
              <div className="flex items-center mb-2 ">
                <FaMapLocationDot className="text-white font-bold text-4xl bg-black rounded-md p-2 mr-2" />
                <div>
                  <p className='leading-none'>Sector 43, Gurgaon - 122002, Haryana, INDIA</p>
                </div>
              </div>
            </div>
            <div className="mb-1 md:mb-0">
              <div className="flex items-center mb-2">
                <MdEmail className="text-white font-bold text-4xl bg-black rounded-md p-2 mr-2" />
                <p>hello@falhari.com</p>
              </div>
            </div>
            <div className="mb-1 md:mb-0">
              <div className="flex items-center mb-6">
                <MdPhone className="text-white font-bold text-4xl bg-black rounded-md p-2 mr-2" />
                <p>9810387325</p>
              </div>
            </div>
          </div>
        </div>
        <div style={{
          height: '75px',
          overflow: 'hidden',
          color: '#fff',
          opacity: '15%',
          textAlign: 'center',
          textShadow: '-1px -1px 0 red, 1px -1px 0 red, -1px 1px 0 red, 1px 1px 0 red' // Stroke effect
        }}>
          <p className='text-8xl'>Falhari</p>
        </div>
      </footer>
      <p className='text-center mr-2'>©Falhari 2024</p>

      <div className='bg-white h-8 flex justify-center items-center'>
        <Link to='/privacy-policy' className='text-black-500 hover:underline mx-2'>Privacy Policy</Link>
        <Link to='/terms-conditions' className='text-black-500 hover:underline mx-2'>Terms & Conditions</Link>
      </div>
    </div>
  );
};

export default Footer;
