import React, { useState, useRef, useEffect } from 'react';
import YouTube from 'react-youtube';
import './YtEmbed.css'; // Import CSS for styling
import FalhariST from "../images/FalhariST.png";

function YtEmbed() {
  const [isVideoVisible, setVideoVisible] = useState(false);
  const youtubePlayerRef = useRef(null);
  const [thumbnailDimensions, setThumbnailDimensions] = useState({ width: 800, height: 450 });
  const [videoDimensions, setVideoDimensions] = useState({ width: 800, height: 450 });

  const videoId = '8_AiIThX93c?si=p8vAU5fu85nmX6E5';
  const thumbnailUrl = FalhariST;

  const showVideo = () => {
    setVideoVisible(true);
  };

  const onReady = (event) => {
    event.target.playVideo(); // Start playing the video when it's ready
  };

  useEffect(() => {
    // Update dimensions based on screen width
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setThumbnailDimensions({ width: (screenWidth * 14) / 16, height: (screenWidth * 9) / 16 });
        setVideoDimensions({ width: (screenWidth * 14) / 16, height: (screenWidth * 9) / 18 });
      } else {
        setThumbnailDimensions({ width: 800, height: 450 });
        setVideoDimensions({ width: 800, height: 450 });
      }
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Initial call to set dimensions
    handleResize();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {!isVideoVisible && (
        <div className="thumbnail-container">
          <img
            src={thumbnailUrl}
            alt="Custom Thumbnail"
            onClick={() => {
              showVideo();
              if (youtubePlayerRef.current) {
                youtubePlayerRef.current.internalPlayer.playVideo();
              }
            }}
            className="custom-thumbnail"
            style={{ cursor: 'pointer', width: `${thumbnailDimensions.width}px`, height: `${thumbnailDimensions.height}px` }}
          />
        </div>
      )}
      {isVideoVisible && (
        <YouTube
          videoId={videoId}
          opts={{
            width: `${videoDimensions.width}`,
            height: `${videoDimensions.height}`,
            playerVars: {
              controls: 0,
              modestbranding: 1,
              fs: 12,
              showinfo: 0,
              rel: 0,
              disablekb: 1,
            },
          }}
          ref={youtubePlayerRef}
          onReady={onReady}
          className="video-player"
        />
      )}
    </div>
  );
}

export default YtEmbed;
