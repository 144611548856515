import React from "react";
import BlogImg from '../images/BlogLanding.png';
import FetchDataFromFirestore from "../components/db/BlogCards";
import BlogCategoryCard from "../components/db/BlogCategoryCard";
function Blog(){

    return(
    <div>
    <div className="h-fit pt-16 mb-8 lg:flex lg:h-500px lg:w-10/12 lg:m-auto">
    <div className='lg:w-1/2 '>
    <img className='w-full h-60 lg:h-500px lg:w-full m-auto object-cover' src={BlogImg}/>
    </div>
    <div className='px-4 lg:w-1/2 lg:px-2 lg:pl-24 lg:py-20'>
        <h1 className='text-md font-semibold lg:text-2xl'>Get insights about</h1>

      <h1 className='text-3xl color font-bold lg:text-6xl'>Fruits And Health Issues</h1>
      {/* <h1 className='text-4xl font-bold color pb-2 lg:text-6xl'>#FalhariTreat</h1> */}

      <div className='flex gap-x-2  m-auto mt-3 lg:pl-1 lg:w-full '>
        <a href="/blogsSection" className='border-2 border-black px-2 py-1 font-medium text-white text-sm bg-black lg:text-xl lg:px-4' >
        Read Blogs
        </a>
       
    </div>
    
    </div>
    </div>

    <div id="blogsSection" className="lg:mt-16">
        <FetchDataFromFirestore/>
    </div>
    </div>
)};

export default Blog;