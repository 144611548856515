import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase";
import WriteDataToFirestore from "./index";
import { getDocs, collection } from 'firebase/firestore';
import data from "./data";
import Logo from './Logo.png'; 

function FetchDataFromFirestore() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const collectionRef = collection(db, 'users');
      const snapshot = await getDocs(collectionRef);
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUserData(data);
    } catch (error) {
      console.error('Error fetching data from Firestore:', error);
    }
  };

  const colors = [
    "bg-red-300",
    "bg-green-300",
    "bg-blue-300",
    "bg-orange-300",

    "bg-yellow-300",
    "bg-purple-300",
    "bg-pink-300",
    'bg-indigo-300'
  ];

  const getColorByIndex = (index) => {
    return colors[index % colors.length];
  };

  return (
    <div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-1 lg:gap-2 m-1 lg:w-4/5 lg:m-auto">
        {Object.keys(data).map((key, index) => {
          const blog = data[key];
          return (
            <Link key={key} to={`/blog/${key}`}>
              <div className={`rounded shadow-xl p-2 h-44 ${getColorByIndex(index)}`}>
                <div className="flex justify-between">
                  <img className="w-8" src={Logo} alt="Logo"/>
                  <h3 className="pt-1 text-sm text-gray-600">Falhari</h3>
                </div>
                <h3 className="font-semibold text-sm h-4/5 pt-4">{blog.title}</h3>
              </div>
            </Link>
          );
        })}
      </div>
      <WriteDataToFirestore />
    </div>
  );
}

export default FetchDataFromFirestore;
