import React, { useState } from 'react';
import { CiSquareMore } from "react-icons/ci";
import { Link } from 'react-router-dom';
import Logo from '../images/Logo.png';
import Word from '../images/Wordmark.png';
import { IoIosCloseCircleOutline } from "react-icons/io";
import ContactForm from '../components/ContactForm';
import Blogs from '../images/Blogs.png';
import Bot from '../images/bot.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track menu open/close
  const [whatsappPopup, setWhatsappPopup] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu state
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Close menu
  };

  const [showPopup, setShowPopup] = useState(false);
  const [launchShowPopup, setLaunchShowPopup] = useState(false);

  const showWhatsappPopup = () => {
    setWhatsappPopup(!whatsappPopup);
  };

  const handleOrderButtonClick = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 768) {
      showWhatsappPopup();
    } else {
      window.location.href = 'https://wa.me/message/5TJSEZZBZM5LM1';
    }
  };

  const LaunchingPopup = () => {
    setLaunchShowPopup(!launchShowPopup);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <nav className="z-50 fixed bg-white w-full border-b-2 shadow-sm">
      <div className="container mx-auto flex justify-between items-center relative">
        <div className="text-black font-bold text-lg p-1 flex lg:ml-28">
          <img className='w-12 h-12 object-fit' src={Logo} alt="Logo" />
          <img className='w-28 h-12 object-cover pr-3' src={Word} alt="Wordmark" />
        </div>
        {/* Toggle button for mobile */}
        <button
          onClick={toggleMenu}
          className="lg:hidden text-black focus:outline-none pr-2"
        >
          <CiSquareMore className="h-6 w-6" />
        </button>
        {/* Mobile sidebar menu */}
        <div
          className={`lg:hidden absolute top-0 left-0 right-0 w-full bg-white border transform transition-transform ease-in-out duration-300 ${isMenuOpen ? 'translate-y-14' : '-translate-y-full'}`}
        >
          <div className="bg-white-800 p-4 rounded text-black w-full h-full flex flex-col justify-center">
            <ul className="text-center">
              <li className="mb-4">
                <Link to="/" onClick={closeMenu} className="text-black hover:text-gray-600">Home</Link>
              </li>
              <li className="mb-4">
                <Link to="/corporate" onClick={closeMenu} className="text-black hover:text-gray-600">Corporate</Link>
              </li>
              
              <li className=" mb-4">
                <Link to="/products" onClick={closeMenu} className="text-black hover:text-red-500">Products</Link>
              </li>

              {/* <li className=" mb-4">
                <Link to="/about" onClick={closeMenu} className="text-black hover:text-red-500">About Us</Link>
              </li> */}
              <li className="mb-4">
                <Link to="/contactus" onClick={closeMenu} className="text-black hover:text-red-500">Contact Us</Link>
              </li>
              <li onClick={() => { togglePopup(); closeMenu(); }} className="mb-4">
                <Link to="#" className="text-black hover:text-gray-600">Get In Touch</Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Desktop navigation */}
        <ul className="hidden lg:flex p-4 mr-20">
          <li className="mr-6">
            <Link to="/" className="text-black font-semibold hover:text-red-500">Home</Link>
          </li>
          
          <li className="mr-6">
            <Link to="/products" className="text-black font-semibold hover:text-red-500">Products</Link>
          </li>
          <li className="mr-6">
            <Link to="/corporate" className="text-black font-semibold hover:text-red-500">Corporate</Link>
          </li>
          <li className="mr-6">
            <Link to="/blogs" className="text-black font-semibold hover:text-red-500">Blogs</Link>
          </li>

          {/* <li className="mr-6">
            <Link to="/about" className="text-black font-semibold hover:text-red-500">About Us</Link>
          </li> */}
          <li className="mr-6">
            <Link to="/contactus" className="text-black font-semibold hover:text-red-500">Contact Us</Link>
          </li>
          <li onClick={togglePopup}>
            <Link to="#" className="text-black border px-8 py-2 border-2 border-black rounded-full font-semibold hover:bg-red-500 mr-2">Schedule Meet</Link>
          </li>
          <li onClick={handleOrderButtonClick}>
            <Link to="#" className="text-white border px-4 py-2 bg-theme border-1 border-black font-semibold hover:bg-red-500 rounded-full">Order Online</Link>
          </li>
        </ul>

        {showPopup && (
          <div className="popup-wrapper">
            <div className="popup-content">
              <IoIosCloseCircleOutline className="close-icon" onClick={togglePopup} />
              <ContactForm />
            </div>
          </div>
        )}

        {launchShowPopup && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded w-2/5">
              <IoIosCloseCircleOutline className="close-icon absolute top-0 right-0 mt-2 mr-2 cursor-pointer text-white font-bold text-2xl" onClick={LaunchingPopup} />
              <img className='w-5/6 m-auto' src={Blogs} alt="Launching Soon" />
              <p className='text-3xl font-bold'>Launching Soon</p>
              <p className='text-xl font-medium'>Stay tuned</p>
              <button className='bg-theme px-4 py-2 text-white text-semibold rounded' onClick={LaunchingPopup}>OK</button>
            </div>
          </div>
        )}

        {whatsappPopup && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded w-2/5">
              <IoIosCloseCircleOutline className="close-icon absolute top-0 right-0 mt-2 mr-2 cursor-pointer text-black font-bold text-2xl" onClick={showWhatsappPopup} />
              <img className='w-4/6 m-auto object-cover' src={Bot} alt="Ordering Made Easy Bot" />
              <p className='text-xl font-bold'>Ordering Made Easy</p>
              <p className='text-3xl font-bold'>Order Directly Via <span className='text-green-500'>Whatsapp</span></p>
              <p className='text-xl font-medium text-red-500'>*Note: Please Use Mobile for Better Experience</p>
              <button className='bg-theme px-4 py-2 text-white text-semibold rounded' onClick={showWhatsappPopup}>OK</button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
