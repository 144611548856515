import React, { useState } from "react";
import SearchContainer from "../components/Search";
import Product from "../components/Products/Product";

const ProductCatalogue = () => {
  const [filteredProducts, setFilteredProducts] = useState([]); // To store filtered products
  const [selectedTags, setSelectedTags] = useState([]); // Store selected tags

  const handleTagClick = (tags) => {
    setSelectedTags(tags); // Update selected tags
  };

  return (
    <div>
      <SearchContainer setFilteredProducts={setFilteredProducts} setSelectedTag={handleTagClick} />
      <Product products={filteredProducts} selectedTags={selectedTags} />
    </div>
  );
};

export default ProductCatalogue;
